import React, { useState, useEffect } from "react";
import "./../ChatContainer/ChatContainer.css";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { IMaskInput } from "react-imask";
import Switch from '../Swicth/Switchs'
import Apospagamento from '../apospagamento/instrucoesPg'
import HowPlanner from '../ComoMontar/HowPlanner'



// import ErrorCard from "../Error/ErrorCard2";

function ChatContainer({
  sendRequest, sendRequestTreinoF, sendRequestTreinoM
}) {


  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [weight, setWeight] = useState("");
  const [isFocused, setIsFocused] = useState(false); // estado para controlar o foco
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");
  const [goal, setGoal] = useState("");
  const [horarios, setHorarios] = useState("");
  const [chocolate, setChocolate] = useState("");
  const [treino, setTreino] = useState("")
  const [selectedFoods1, setSelectedFoods1] = useState([]);
  const [selectedFoods2, setSelectedFoods2] = useState([]);
  const [selectedFoods3, setSelectedFoods3] = useState([]);
  const [selectedFoods4, setSelectedFoods4] = useState([]);



  const selecionarGenero = (genero) => {
    setGeneroSelecionado(genero);
  };




  const availableFoodsCarb = [
    { img: "🥙", food: "Tapioca + Frango", foodd: "Tapioca com recheio de Frango" },
    { img: "🥞", food: "Crepioca + Queijo", foodd: "Crepioca com recheio de Queijo" },
    { img: "🍎", food: "Fruta", foodd: "Fruta" },
    { img: "🥛", food: "Iogurte", foodd: "Iogurte" },
    { img: "☕", food: "Café", foodd: "Café" },
    { img: "🧀", food: "Pão de Queijo", foodd: "Pão de Queijo" },
    { img: "🥖", food: "Pão + Ovo", foodd: "Pão com Ovo mexido ou omelete" },
    { img: "☕", food: "Café + Leite", foodd: "Café com Leite" },
    { img: "🍚", food: "Cuscuz ", foodd: "Cuscuz " },
    { img: "🍞", food: "Pão + Queijo", foodd: "Pão com Queijo" },
    { img: "🥓", food: "Pão + Presunto", foodd: "Pão com Presunto e Queijo" },
  ];

  const availableFoodsProtein = [
    { img: "🍗", food: "Arroz Feijão Frango Batata Salada", foodd: "Arroz Batata Inglesa Feijão Frango Salada" },
    { img: "🥩", food: "Arroz Feijão Carne Moída Salada", foodd: "Arroz Carne Moída Feijão Salada" },
    { img: "🥩", food: "Arroz Feijão Carne Bife Farofa", foodd: "Arroz Carne Bife Feijão Farofa" },
    { img: "🐟", food: "Arroz Feijão Alface Peixe", foodd: "Alface Arroz Feijão Peixe" },
    { img: "🍳", food: "Batata Doce Macarrão Ovo Cozido Tomate", foodd: "Batata Doce Macarrão Ovo Cozido Tomate" },
    { img: "🥔", food: "Arroz Feijão Batata Inglesa Frango", foodd: "Arroz Batata Inglesa Feijão Frango" },
    { img: "🍖", food: "Macarrão Batata Inglesa Peixe", foodd: "Macarrão Batata Inglesa Peixe" },
    { img: "🥗", food: "Arroz Alface Tomate Omelete", foodd: "Alface Arroz Tomate Omelete" },
    { img: "🍛", food: "Carne Moída Macarrão Mandioca", foodd: "Carne Moída Macarrão Mandioca" },
    { img: "🍗", food: "Arroz Feijão Frango Inhame Salada", foodd: "Arroz Feijão Frango Inhame Salada" },
    { img: "🍖", food: "Arroz Feijão Carne Brocolis Mandioca", foodd: "Arroz Brocolis Carne Feijão Mandioca" },
    { img: "🍗", food: "Inhame Feijão Batata Inglesa Frango", foodd: "Inhame Feijão Batata Inglesa Frango" }
  ];

  const availableFoodsProtein2 = [
    { img: "🍗", food: "Arroz Feijão Frango Batata Salada", foodd: "Arroz Batata Inglesa Feijão Frango Salada" },
    { img: "🥩", food: "Arroz Feijão Carne Moída Salada", foodd: "Arroz Carne Moída Feijão Salada" },
    { img: "🥩", food: "Arroz Feijão Carne Bife Farofa", foodd: "Arroz Carne Bife Feijão Farofa" },
    { img: "🐟", food: "Arroz Feijão Alface Peixe", foodd: "Alface Arroz Feijão Peixe" },
    { img: "🍳", food: "Batata Doce Macarrão Ovo Cozido Tomate", foodd: "Batata Doce Macarrão Ovo Cozido Tomate" },
    { img: "🥔", food: "Arroz Feijão Batata Inglesa Frango", foodd: "Arroz Batata Inglesa Feijão Frango" },
    { img: "🍖", food: "Macarrão Batata Inglesa Peixe", foodd: "Macarrão Batata Inglesa Peixe" },
    { img: "🥗", food: "Arroz Alface Tomate Omelete", foodd: "Alface Arroz Tomate Omelete" },
    { img: "🍛", food: "Carne Moída Macarrão Mandioca", foodd: "Carne Moída Macarrão Mandioca" },
    { img: "🍗", food: "Arroz Feijão Frango Inhame Salada", foodd: "Arroz Feijão Frango Inhame Salada" },
    { img: "🍖", food: "Arroz Feijão Carne Brocolis Mandioca", foodd: "Arroz Brocolis Carne Feijão Mandioca" },
    { img: "🍗", food: "Inhame Feijão Batata Inglesa Frango", foodd: "Inhame Feijão Batata Inglesa Frango" }
  ]
  
const availableFoodsFrutas = [
  { img: "🥛", food: "Whey", foodd: "Shake de Whey" },
  { img: "🍍", food: "Fruta", foodd: "Fruta" },
  { img: "🍚", food: "Cuscuz", foodd: "Cuscuz com ovo" },
  { img: "🥖", food: "Pão + Ovo", foodd: "Pão com Ovo mexido ou omelete" },
  { img: "🥙", food: "Tapioca + Frango", foodd: "Tapioca com recheio de Frango" },
  { img: "🥞", food: "Crepioca + Queijo", foodd: "Crepioca com recheio de Queijo" },
  { img: "🥛", food: "Leite", foodd: "Leite" },
  { img: "🌯", food: "Rap10 + Frango", foodd: "Rap10 com recheio de Frango" },
  { img: "🥚", food: "Ovo", foodd: "Ovo" },
  { img: "🥪", food: "Sanduíche Frango ", foodd: "Sanduíche Frango " },
  { img: "🥪", food: "Sanduíche de Peru", foodd: "Sanduíche de Peru" },

];


  const [error, setError] = useState("");
  const { retornaTicket, adicionarTicketUsado, salvarDados, retirarTicket, adicionarPrompt } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  // const [userDieta, setUserDieta] = useState(null);
  const [exercicio, setExercicio] = useState("")

  const [genero, setGeneroSelecionado] = useState('');
  const [calorias, setCalorias] = useState('')
  // const [showError3,setShowError3] =  useState(false)




  // const closeError3 = () => {
  //   setShowError3(false);
  // }

  function handleFoodSelectionCarb(
    selectedFoods,
    setSelectedFoods,
    food,
    foodd,
  ) {
    if (selectedFoods.some((item) => item.foodd === foodd)) {
      setSelectedFoods(selectedFoods.filter((item) => item.foodd !== foodd));
    } else if (selectedFoods.length < 10) {
      setSelectedFoods([...selectedFoods, { food, foodd }]);
    }
  }

  function handleFoodSelectionProtein(
    selectedFoods,
    setSelectedFoods,
    food,
    foodd,
  ) {
    if (selectedFoods.some((item) => item.foodd === foodd)) {
      setSelectedFoods(selectedFoods.filter((item) => item.foodd !== foodd));
    } else if (selectedFoods.length < 4) {
      setSelectedFoods([...selectedFoods, { food, foodd }]);
    }
  }

  function handleFoodSelectionFrutas(
    selectedFoods,
    setSelectedFoods,
    food,
    foodd
  ) {
    if (selectedFoods.some((item) => item.foodd === foodd)) {
      setSelectedFoods(selectedFoods.filter((item) => item.foodd !== foodd));
    } else if (selectedFoods.length < 10) {
      setSelectedFoods([...selectedFoods, { food, foodd }]);
    }
  }

  function handleFoodSelectionJanta(
    selectedFoods,
    setSelectedFoods,
    food,
    foodd
  ) {
    if (selectedFoods.some((item) => item.foodd === foodd)) {
      setSelectedFoods(selectedFoods.filter((item) => item.foodd !== foodd));
    } else if (selectedFoods.length < 4) {
      setSelectedFoods([...selectedFoods, { food, foodd }]);
    }
  }



  // console.log(showError3)
  // useEffect(() => {
  //   retornaDieta()
  //     .then(async (dieta) => {
  //       if (dieta !== null) {
  //         await setUserDieta(dieta);
  //       } else {
  //         setUserDieta('');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Erro ao obter os tickets:', error);
  //       setUserDieta('Erro ao obter os tickets');
  //     });
  // },[retornaDieta])


  useEffect(() => {
    retornaTicket()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
        } else {
          setUserTickets("Nenhum ticket disponível");

        }
      })
      .catch((error) => {
        setUserTickets("Erro ao obter os tickets");
      });


  }, [retornaTicket]);

  const handleSubmitSemTicket = async () => {
    if (!weight || !height ) {
      setError("Por favor, coloque os campos de altura e peso apenas com números.");
      return;  
    }else if (!goal) {
      setError("Por favor, selecione o seu Objetivo");
      return;
    }else if (!calorias) {
      setError("Por favor, selecione as Calorias");
      return;
    }else if (!genero) {
      setError("Por favor, selecione o seu de Gênero");
      return;
    }else if (selectedFoods3.length === 0 || selectedFoods2.length === 0 || selectedFoods1.length === 0 || selectedFoods4.length === 0) {
      setError("Por favor, selecione seus alimentos");
      return;
    }else if (!treino) {
      setError("Por favor, selecione o tipo de Treino");
      return;
    }

  const HomensTMB = parseInt((10 * weight + 6.25 * (height * 100) - 5 * age + 5) * exercicio)
    const MulheresTMB = parseInt((10 * weight + 6.25 * (height * 100) - 5 * age - 161) * exercicio)

    function calcularCalorias(goal, genero) {
      switch (goal) {
        case "Emagrecimento com facilidade":
          return genero === 'Masculino' ? `${HomensTMB - 700} a ${HomensTMB - 500}` : `${MulheresTMB - 700} a ${MulheresTMB - 500}`;
        case "Emagrecimento com facilidade + ganho de massa":
          return genero === 'Masculino' ?`${HomensTMB - 400} a ${HomensTMB - 200}`: `${MulheresTMB - 400} a ${MulheresTMB - 300}`;
        case "Jejum Intermitente":
          return genero === 'Masculino' ? `${HomensTMB - 600} a ${HomensTMB - 500}` : `${MulheresTMB - 600} a ${MulheresTMB - 500}`;
        case "Definição Muscular":
          return genero === 'Masculino' ? `${HomensTMB - 300} a ${HomensTMB - 200}` : `${MulheresTMB - 300} a ${MulheresTMB - 200}`;
        case "Bulking":
          return genero === 'Masculino' ? `${HomensTMB + 500} a ${HomensTMB + 700}` : `${MulheresTMB + 500} a ${MulheresTMB + 700}`;
        case "Definição e ganho de Massa Muscular":
          return genero === 'Masculino' ? `${HomensTMB + 200} a ${HomensTMB + 400}` : `${MulheresTMB + 200} a ${MulheresTMB + 400}`;
        default:
          return genero === 'Masculino' ? `${HomensTMB - 100} a ${HomensTMB + 100}  ` :  `${MulheresTMB - 100} a ${MulheresTMB + 100}  `

      }
    }

    const data = `Crie exclusivamente um plano de dieta para atingir o objetivo de ${goal}, seguindo estas diretrizes:
    - Utilize esses horarios para as refeições ${horarios}
    - Apresente todas as quantidades em gramas e inclua as calorias ao final de cada opção.
    - Elabore o plano usando somente os alimentos listados, certifique-se que os alimentos das opções sejam combinadas de forma correta, variando entre as refeições:
      - 3 opções variadas Café da manhã: ${selectedFoods1.map((item) => item.foodd).join(", ")};
      - 3 opções variadas Lanche da manhã: ${selectedFoods3.map((item) => item.foodd).join(", ")};
      - 4 opções variadas Almoço: ${selectedFoods2.map((item) => item.foodd).join(", ")};
      - 3 opções variadas Lanche da tarde: ${selectedFoods3.map((item) => item.foodd).join(", ")};
      - 4 opções variadas Jantar: ${selectedFoods4.map((item) => item.foodd).join(", ")};
    - Finalize o plano sem adicionar mensagens e sem observações após a última opção de jantar e inclua ${chocolate}.
    - Garanta que a soma das refeições para cada conjunto de opções (Opção 1, Opção 2 e Opção 3) separadamente seja de aproximadamente ${ calorias === 'Não' ? calcularCalorias(goal, genero) : calorias} calorias e distribua as quantidades e calorias entre as refeições
    - Formate a dieta em Café da Manhã: *Opção 1:* *Opção 2:* *Opção 3:* faça sucessivamente com lanche da manha e tarde, almoço com 4 Opções e janta com 4 Opções, deixe os alimentos em cada linha
    `;

    if (userTickets === 0 || userTickets === undefined) {
      await adicionarPrompt(data, treino, genero)
      const imc = weight / (height * height)
      await salvarDados(weight, height, parseInt(imc))
      navigate('/planos');
      return;
  }
}

  const handleSubmit = async () => {
    const HomensTMB = parseInt((10 * weight + 6.25 * (height * 100) - 5 * age + 5) * exercicio)
    const MulheresTMB = parseInt((10 * weight + 6.25 * (height * 100) - 5 * age - 161) * exercicio)

    function calcularCalorias(goal, genero) {
      switch (goal) {
        case "Emagrecimento com facilidade":
          return genero === 'Masculino' ? `${HomensTMB - 700} a ${HomensTMB - 500}` : `${MulheresTMB - 700} a ${MulheresTMB - 500}`;
        case "Emagrecimento com facilidade + ganho de massa":
          return genero === 'Masculino' ?`${HomensTMB - 400} a ${HomensTMB - 200}`: `${MulheresTMB - 400} a ${MulheresTMB - 300}`;
        case "Jejum Intermitente":
          return genero === 'Masculino' ? `${HomensTMB - 600} a ${HomensTMB - 500}` : `${MulheresTMB - 600} a ${MulheresTMB - 500}`;
        case "Definição Muscular":
          return genero === 'Masculino' ? `${HomensTMB - 300} a ${HomensTMB - 200}` : `${MulheresTMB - 300} a ${MulheresTMB - 200}`;
        case "Bulking":
          return genero === 'Masculino' ? `${HomensTMB + 500} a ${HomensTMB + 700}` : `${MulheresTMB + 500} a ${MulheresTMB + 700}`;
        case "Definição e ganho de Massa Muscular":
          return genero === 'Masculino' ? `${HomensTMB + 200} a ${HomensTMB + 400}` : `${MulheresTMB + 200} a ${MulheresTMB + 400}`;
        default:
          return genero === 'Masculino' ? `${HomensTMB - 100} a ${HomensTMB + 100}  ` :  `${MulheresTMB - 100} a ${MulheresTMB + 100}  `

      }
    }

   
    const data = `Crie exclusivamente um plano de dieta para atingir o objetivo de ${goal}, seguindo estas diretrizes:
    - Utilize esses horarios para as refeições ${horarios}
    - Apresente todas as quantidades em gramas e inclua as calorias ao final de cada opção.
    - Elabore o plano usando somente os alimentos listados, certifique-se que os alimentos das opções sejam combinadas de forma correta, variando entre as refeições:
      - 3 opções variadas Café da manhã: ${selectedFoods1.map((item) => item.foodd).join(", ")};
      - 3 opções variadas Lanche da manhã: ${selectedFoods3.map((item) => item.foodd).join(", ")};
      - 4 opções variadas Almoço: ${selectedFoods2.map((item) => item.foodd).join(", ")};
      - 3 opções variadas Lanche da tarde: ${selectedFoods3.map((item) => item.foodd).join(", ")};
      - 4 opções variadas Jantar: ${selectedFoods4.map((item) => item.foodd).join(", ")};
    - Finalize o plano sem adicionar mensagens e sem observações após a última opção de jantar e inclua ${chocolate}.
    - Garanta que a soma das refeições para cada conjunto de opções (Opção 1, Opção 2 e Opção 3) separadamente seja de aproximadamente ${ calorias === 'Não' ? calcularCalorias(goal, genero) : calorias} calorias e distribua as quantidades e calorias entre as refeições
    - Formate a dieta em Café da Manhã: *Opção 1:* *Opção 2:* *Opção 3:* faça sucessivamente com lanche da manha e tarde, almoço com 4 Opções e janta com 4 Opções, deixe os alimentos em cada linha
    `;

   
    if (!weight || !height ) {
      setError("Por favor, coloque os campos de altura e peso apenas com números.");
      return;  
    }else if (!goal) {
      setError("Por favor, selecione o seu Objetivo");
      return;
    }else if (!calorias) {
      setError("Por favor, selecione as Calorias");
      return;
    }else if (!genero) {
      setError("Por favor, selecione o seu de Gênero");
      return;
    }else if (selectedFoods3.length === 0 || selectedFoods2.length === 0 || selectedFoods1.length === 0 || selectedFoods4.length === 0) {
      setError("Por favor, selecione seus alimentos");
      return;
    }else if (!treino) {
      setError("Por favor, selecione o tipo de Treino");
      return;
    }

    setIsSubmitting(true);
    document.body.classList.add("scroll-lock");
    const imc = weight / (height * height)
    salvarDados(weight, height, parseInt(imc))


    try {
      if (userTickets === "DietaTreino" || userTickets === "DietaNormal")
       if (treino !== 'Não' && userTickets === "DietaTreino") {
         if (genero === 'Feminino') {
         sendRequestTreinoF(data, treino)
         } else if (genero === 'Masculino') {
       sendRequestTreinoM(data, treino)
         }
       } else {
        sendRequest(data)
       }

      
     


      setGoal("");
      setHorarios("");
      setChocolate("");
      setSelectedFoods1([]);
      setSelectedFoods2([]);
      setSelectedFoods3([]);
      setSelectedFoods4([]);
      setError("");






    }catch (err) {
      setError('Estamos com bastante requisições! Coloque suas informações novamente para montar sua dieta');
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
      await retirarTicket()
      adicionarTicketUsado()
      document.body.classList.remove('scroll-lock');
      navigate('/carregando')// Remova a classe
    }
  };
  return (
    <div className="chat-conteiner mt-0 mb-0 mx-auto">
    <div className="chat-inputs mx-auto p-4 pt-3 mb-4  rounded-[19px] shadow-lg" style={{ border: '1.0px solid #D9D8D8' }}>
    <div className="chat-header">
        <Switch/>
        </div>
        <br></br>
        <div className="chat-input chat-input-peso">
        <label htmlFor="weight" className={`weight-label ${!isFocused && !weight ? 'bg-green-100 text-green-600 visible' : 'text-green-600'}`}>Informe seu peso</label>
      <IMaskInput
       mask={'000'}
        type="number"
        id="weight"
        maxLength={4}
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
        placeholder="Peso"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        
        className="input-style"

          />
        </div>
        <div className="chat-input">

          <IMaskInput
            mask="0.00"
            type="number"
            id="height"
            maxLength={4}
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder="Altura "
          />
        </div>

        <div className="chat-input">

          <IMaskInput
            mask={'00'}
            type="number"
            id="age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder="Idade "

          />
        </div>
        <div className="select-wrapper">
  <select id="goal" value={goal} onChange={(e) => setGoal(e.target.value)}>
    <option value="">Selecione seu objetivo</option>
    <option value="Emagrecimento com facilidade">Emagrecer</option>
    <option value="Bulking">Ganho Massa Muscular</option>
    <option value="Definição e ganho de Massa Muscular">Definição + Massa Muscular</option>
    <option value="Definição Muscular">Definição</option>
    <option value="Emagrecimento com facilidade + ganho de massa">Emagrecer + Massa Muscular</option>
  </select>
</div>
<div className="select-wrapper">
  <select id="treino" value={calorias} onChange={(e) => setCalorias(e.target.value)}>
    <option value="">Calorias desejadas 🔥</option>
    <option value="Não">Não sei dizer</option>
    <option value="1200 a 1500">1200 a 1500 calorias</option>
    <option value="1600 a 1900">1600 a 1900 calorias</option>
    <option value="2000 a 2300">2000 a 2300 calorias</option>
    <option value="2400 a 2700">2400 a 2700 calorias</option>
    <option value="2800 a 3100">2800 a 3100 calorias</option>
    <option value="3200 a 3500">3200 a 3500 calorias</option>
  </select>
</div>
        <div className="chat-input p-0 m-0">

          <br></br>
          <div className=" w-full flex justify-evenly p-0">
            <button
              className={`button-inputM py-3 px-4 md:px-5 ${genero === 'Masculino' ? 'selected' : ''}`}
              onClick={() => selecionarGenero('Masculino')}
            >
              Masculino
            </button>
            <button
              className={`button-inputF py-3 px-4 md:px-5 ${genero === 'Feminino' ? 'selectedF' : ''}`}
              onClick={() => selecionarGenero('Feminino')}
            >
              Feminino
            </button></div>

      </div>
      </div>
      <div className="selection-food max-w-mx mx-auto p-6 bg-card rounded-lg shadow-lg" style={{ border: '1.0px solid #D9D8D8' }}>
        <label htmlFor="obs" className="text-2xl font-bold text-foreground mb-6 text-center">Café da manhã ☕</label>
     <div className="food-container">
          {availableFoodsCarb.map((food, index) => (
            <label key={index} className={`food-item flex w-[100%] justify-center items-center text-sm md:text-lg ${selectedFoods1.some((item) => item.foodd === food.foodd) ? 'selectedfood ' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={food.food}
                checked={selectedFoods1.some((item) => item.foodd === food.foodd)}
                onChange={() =>
                  handleFoodSelectionCarb(
                    selectedFoods1,
                    setSelectedFoods1,
                    food.food,
                    food.foodd
                  )
                }
                disabled={
                  selectedFoods1.length === 10 &&
                  !selectedFoods1.some((item) => item.foodd === food.foodd)
                }

              />
              <span className="checkmark">
                {food.food}
                {food.img}
              </span>
            </label>
          ))}
        </div>
      </div>
      <div className="selection-food max-w-mx mx-auto p-6 bg-card rounded-lg shadow-lg" style={{ border: '1.0px solid #D9D8D8' }}>
        <label htmlFor="obs" className="text-2xl font-bold text-foreground mb-6 text-center">Almoço 🍽️</label>
        <div className="food-container">
          {availableFoodsProtein.map((food, index) => (
            <label key={index} className={`food-item flex w-[100%] py-10 px-1 justify-center items-center text-sm md:text-lg ${selectedFoods2.some((item) => item.foodd === food.foodd) ? 'selectedfood' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={food.food}
                checked={selectedFoods2.some((item) => item.foodd === food.foodd)}
                onChange={() =>
                  handleFoodSelectionProtein(
                    selectedFoods2,
                    setSelectedFoods2,
                    food.food,
                    food.foodd,

                  )
                }
                disabled={
                  selectedFoods2.length === 4 &&
                  !selectedFoods2.some((item) => item.foodd === food.foodd)
                }
              />
              <span className="checkmark">
                {food.food}
                {food.img}
              </span>
            </label>
          ))}
        </div>
      </div>

      <div className="selection-food flex flex-col border rounded-lg font-bold mb-2 p-6 shadow-lg" style={{ border: '1.0px solid #D9D8D8' }}>
        <label htmlFor="obs3" className="text-color text-xl font-bold text-foreground mb-6 text-center">Lanche da Manha e Tarde 🥪</label>
        <div className="food-container">
          {availableFoodsFrutas.map((food, index) => (
            <label key={index} className={`food-item flex w-[100%] justify-center items-center text-sm md:text-lg ${selectedFoods3.some((item) => item.foodd === food.foodd) ? 'selectedfood' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={food.food}
                checked={selectedFoods3.some((item) => item.foodd === food.foodd)}
                onChange={() =>
                  handleFoodSelectionFrutas(
                    selectedFoods3,
                    setSelectedFoods3,
                    food.food,
                    food.foodd
                  )
                }
                disabled={
                  selectedFoods3.length === 10 &&
                  !selectedFoods3.some((item) => item.foodd === food.foodd)
                }
              />
              <span className="checkmark">
                {food.food}
                {food.img}
              </span>
            </label>
          ))}
        </div>
      </div>

      <div className="selection-food max-w-mx mx-auto p-6 bg-card rounded-lg shadow-lg" style={{ border: '1.0px solid #D9D8D8' }}>
        <label htmlFor="obs" className="text-2xl font-bold text-foreground mb-6 text-center">Janta 🍽️</label>
        <div className="food-container">
          {availableFoodsProtein2.map((food, index) => (
            <label key={index} className={`food-item flex w-[100%] py-10 px-1 justify-center items-center text-sm md:text-lg ${selectedFoods4.some((item) => item.foodd === food.foodd) ? 'selectedfood' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={food.food}
                checked={selectedFoods4.some((item) => item.foodd === food.foodd)}
                onChange={() =>
                  handleFoodSelectionJanta(
                    selectedFoods4,
                    setSelectedFoods4,
                    food.food,
                    food.foodd,

                  )
                }
                disabled={
                  selectedFoods4.length === 4 &&
                  !selectedFoods4.some((item) => item.foodd === food.foodd)
                }
              />
              <span className="checkmark">
                {food.food}
                {food.img}
              </span>
            </label>
          ))}
        </div>
      </div>
      <br />

      <div className="chat-inputs mx-auto shadow-lg rounded-lg "style={{ border: '1.3px solid #D9D8D8' }}>
        <div className="flex justify-center text-center">
        <p className="text-2xl font-bold text-foreground mb-0 text-center">
  Treinos e Atividades 🏋🏻‍♀️
</p>
        </div>
        <br></br>
       <div className="select-wrapper">
          <select
            id="exercicio"
            value={exercicio}
            onChange={(e) => setExercicio(e.target.value)}
          >
            <option value="1.1">Nível de Atividade Física</option>
            <option value="1.2">Sedentário (pouca ou nenhuma atividade física)</option>
            <option value="1.3">Levemente ativo (exercícios 1 a 3 vezes por semana)</option>
            <option value="1.5">Moderadamente ativo (exercícios de 3 a 5 vezes por semana)</option>
            <option value="1.7">Altamente ativo (exercícios de 5 a 7 dias por semana)</option>
            <option value="1.8"> Extremamente ativo (exercícios todos dias e faz trabalho braçal) </option>


          </select>
        </div>

        <div className="select-wrapper">
          <select
            id="treino"
            value={treino}
            onChange={(e) => setTreino(e.target.value)}
          >
            <option value="">Deseja treino?</option>
            <option value="Academia">Sim, Treino na academia</option>
            <option value="caseiro">Sim, Treino em casa</option>
            <option value="Não">Não</option>

          </select>
        </div>
      </div>
<br></br>

      <div className="chat-inputs mx-auto shadow-lg rounded-lg"style={{ border: '1.3px solid #D9D8D8' }}>
        <div className="flex justify-center text-center">
        <p className="text-2xl font-bold text-foreground mb-0 text-center">
  Adicionais na Dieta 🥗
</p>
        </div>
        
        <br></br>
       <div className="select-wrapper">
       <select
            id="horarios"
            value={horarios}
            onChange={(e) => setHorarios(e.target.value)}
          >
            <option value="">Horários de cada refeição</option>
            <option value="Sem Horarios">Tenho meu próprio horário</option>
            <option value="05:30, 08:30, 12:00, 15:00, 19:00">05:30, 08:30, 12:00, 15:00, 19:00</option>
            <option value="06:00, 09:00, 12:00, 15:00, 19:00">06:00, 09:00, 12:00, 15:00, 19:00 </option>
            <option value="06:30, 09:30, 13:00, 16:00, 20:00">06:30, 09:30, 13:00, 16:00, 20:00</option>
            <option value="07:00, 10:00, 12:30, 15:30, 19:30">07:00, 10:00, 12:30, 15:30, 19:30</option>
            <option value="07:30, 10:30, 12:00, 15:00, 19:00">07:30, 10:30, 12:00, 15:00, 19:00</option>
            <option value="08:00, 11:00, 13:30, 16:30, 20:30">08:00, 11:00, 13:30, 16:30, 20:30</option>     
            <option value="09:00, 11:00, 13:00, 16:00, 20:00">09:00, 11:00, 13:00, 16:00, 21:00</option>

          </select>
        </div>

        <div className="select-wrapper">
        <select
            id="Chocolate"
            value={chocolate}
            onChange={(e) => setChocolate(e.target.value)}
          >
            <option value="">Chocolate na Dieta? </option>
            <option value="Não adicione chocolate na dieta">Não, obrigado</option>
            <option value="adicione apenas UM chocolate Bis na dieta">Sim, um Bis.🍫</option>
            <option value="adicione apenas UM chocolate Prestígio na dieta">Sim, um Prestígio.🍫</option>
            <option value="adicione apenas UM chocolate Trento na dieta">Sim, um Trento.🍫</option>
            <option value="adicione apenas UM chocolate Baton na dieta">Sim, um Baton.🍫</option>
            <option value="adicione apenas UM chocolate Chokito na dieta">Sim, um Chokito.🍫</option>
            <option value="adicione apenas UM chocolate Sonho de Valsa na dieta">Sim, um Sonho de Valsa.🍫</option>
          </select>
        </div>
      </div>

      <br></br>
      {userTickets === 0 || userTickets === undefined ? (
  <div className="text-center p-4 bg-green-600 rounded-2xl " style={{ width: '95%', margin: '0 auto', border: '1.3px solid #ffffff' }}>
  <span className="inline-block bg-green-50 text-green-800 font-semibold p-2 rounded-full text-lg ">
    Mais acessível que um café! ☕
  </span>          
  <p className="text-white mt-4">
    Por menos de <span className="font-bold text-green-50 underline">R$10,00</span>, Você monta sua Dieta <span className="font-bold text-green-50">TOTALMENTE PERSONALIZADA</span>.
  </p>
  <button
    className="w-full bg-green-50  text-green-800  py-3 px-4 rounded-full shadow-md transition-colors duration-300 ease-in-out font-bold"
    onClick={handleSubmitSemTicket}
  >
    MONTAR MINHA DIETA
  </button>


  {error && (
            <div className="chat-error text-white bg-red-600 rounded-md mb-2 mt-2">
              {error}
            </div>
          )}
</div>

      ) : (
        <div>
          <button
            className={`py-3 px-4 my-3 rounded-full shadow-md transition-colors duration-300 ease-in-out font-bold ${userTickets !== 'DietaTreino' && userTickets !== 'DietaNormal'
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-gradient-to-r from-green-600 to-green-700 text-white hover:from-green-700 hover:to-green-800'
              }`}
            disabled={isSubmitting || (userTickets !== 'DietaTreino' && userTickets !== 'DietaNormal')}
            onClick={handleSubmit}>
            MONTAR MINHA DIETA
          </button>

          {error && (
            <div className="chat-error text-white bg-red-600 rounded-md mb-3">
              {error}
            </div>
          )}
        </div>
      )}
      <br></br>
      <HowPlanner/>
      <br></br>
      <Apospagamento/>
  
    </div>
  );
}

export default ChatContainer;